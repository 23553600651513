export const SIGN_IN = 'SIGN_IN';
export const LOG_OUT = 'LOG_OUT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REGISTER = 'REGISTER';
export const FETCH_CART = 'FETCH_CART';
export const CREATE_CART = 'CREATE_CART';
export const DELETE_CART = 'DELETE_CART';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const HIDE_ERROR = "HIDE_ERROR";
export const HIDE_SUCCESS = "HIDE_SUCCESS"
export const CREATE_CART_ERROR = "CREATE_CART_ERROR"
export const FETCH_CART_ERROR = "FETCH_CART_ERROR"
export const DELETE_CART_ERROR = "DELETE_CART_ERROR"
export const INCREMENT = "INCREMENT"
export const DECREMENT = "DECREMENT"